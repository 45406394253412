import React, { useEffect } from "react";
import "./About.css";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import Image from "react-bootstrap/esm/Image";
import bg from "../../assets/bg.jpeg";

import pic10 from "../../assets/pic10.jpeg";
import pic11 from "../../assets/pic11.jpeg";
import pic12 from "../../assets/pic12.jpeg";
import { Container } from "react-bootstrap";

import AOS from 'aos';


const About = () => {

  useEffect(() => {
    AOS.init();
  }, [])
  

  return (
    <div className="body-about ">
      <Container>
      <div className="pt-5 pb-5 "  >
        <Row >
      
          <Col  xs={5} sm={6} md={7} lg={7}>
            {" "}
            {/* <Image src={pic10} width={400} height={500} />{" "} */}
          <div data-aos="fade-right"  data-aos-easing="ease-in-sine"  data-aos-anchor-placement="bottom-bottom"  style={{position: 'relative', marginTop: '-50px'}} >
          <img src={pic10} className='img1' />
            <img src={pic11}  className='img2' />
          </div>
          </Col>
          <Col data-aos="fade-left"  data-aos-easing="ease-in-sine"   xs={7} sm={6} md={5} lg={5} >
            <h1> Our Story</h1>
            <p>
              {" "}
              I am passionate about food and have explored this passion in a
              number of settings. I began my career in food science, before
              combining my love of travel with my skills and experience in
              hospitality and pursuing a career as a flight attendant. My time
              in the sky came to an end when I decided to return to Australia to
              help run my family’s successful Thai restaurant in the regional
              culinary destination of the Macedon Ranges. The time spent running
              the restaurant reignited my love for the food of Thailand. I was
              able to further explore this passion during Covid. Despite the
              challenges presented to restaurant owners globally, I took the
              downtime as an opportunity to develop a product that would allow
              people to prepare food with delicious Thai flavours in their own
              homes. This is where Dee Thai Foods began.
            </p>
          </Col>
        </Row>
      </div>

      </Container>
    </div>
  );
};

export default About;
