import React, { useEffect } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import Image from "react-bootstrap/Image";
import pic10 from '../../assets/pic10.jpeg'
import pic11 from '../../assets/pic11.jpeg'
import pic12 from '../../assets/pic12.jpeg'

import ic1 from '../../assets/natural-ingredients.png'
import ic2 from '../../assets/vegan.png'
import ic3 from '../../assets/no-fat.png'

import AOS from 'aos';

import { Col, Row } from 'react-bootstrap';
import vdBG from '../../assets/vdBG.mp4'

import './Presen.css'


const Presence = () => {

  useEffect(() => {
    AOS.init();
  }, [])
  

  return (

    <>
          {/* <>
<div>
<video    id='video'>
        <source src={vdBG} type='video/mp4' />  
      </video>
</div>
<div style={{objectFit: 'cover'}}>
<video  autoPlay loop id='video'>
        <source src={vdBG} type='video/mp4' />  
      </video>
</div>
    </> */}
     <div className='container-inside body'>
     <Row   className=''>
        <Col xs={12} sm={12} md={4}>
        <div data-aos="fade-up" data-aos-duration="1500" className='d-flex '   style={{justifyContent: 'space-evenly'}}>
          <Image src={ic1} width={100} />
          <h3>natural ingredients</h3>
        </div>
        </Col>
        <Col xs={12} sm={12} md={4}>
        <div data-aos="fade-up" data-aos-duration="2000" className='d-flex ' style={{justifyContent: 'space-evenly'}}>
          <Image src={ic2} width={100} />
          <h3>  vegan freindly</h3>
        </div>
      </Col>
        <Col xs={12} sm={12} md={4}>
        <div data-aos="fade-up" data-aos-duration="3000" className='d-flex ' style={{justifyContent: 'space-evenly'}} >
          <Image src={ic3} width={100} />
          <h3>  less fat and sugar</h3>
        </div>
        </Col>
      </Row>
     </div>
    </>

  //   <Swiper
  //   spaceBetween={50}
  //   slidesPerView={3}
  //   onSlideChange={() => console.log('slide change')}
  //   onSwiper={(swiper) => console.log(swiper)}
  // >
  //   <SwiperSlide> <Image  width={400} height={500}  src={pic10} /> </SwiperSlide>
  //   <SwiperSlide> <Image  width={500} height={500}  src={pic11} /> </SwiperSlide>
  //   <SwiperSlide><Image   width={700} height={500} src={pic12} />  </SwiperSlide>
  //   <SwiperSlide>Slide 4</SwiperSlide>
  //   ...
  // </Swiper>
  )
}

export default Presence