import React from "react";
import { Carousel } from "react-bootstrap";
import "./Tesimonial.css";

import farm from "../../assets/farm.jpg";
import chilli1 from "../../assets/crispy.jpg";
import redChilli from "../../assets/red-chilli.jpg";

import img1 from "../../assets//img/img1.jpeg";
import img2 from "../../assets//img/img2.jpeg";
import img3 from "../../assets//img/img3.jpeg";
import img4 from "../../assets//img/img4.jpeg";
import img5 from "../../assets//img/img5.jpeg";
import img6 from "../../assets//img/img6.jpeg";
import img7 from "../../assets//img/img7.jpeg";
import img8 from "../../assets//img/img8.jpeg";
import img9 from "../../assets//img/img9.jpeg";
import img10 from "../../assets//img/img10.jpeg";
import img11 from "../../assets//img/img11.jpeg";
import img12 from "../../assets//img/img12.jpeg";

import review from "../../assets/reviiew.jpeg";
import review2 from "../../assets/review2.jpeg";

const Testimonials = () => {
  return (
    <Carousel>
    
      <Carousel.Item>
        <img className="d-block w-100" src={img1} alt="Second slide" />
        <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img2} alt="Third slide" />

        <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img3} alt="Third slide" />
        <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img4} alt="Third slide" />
        <Carousel.Caption>
          <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img5} alt="Third slide" />
        <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img6} alt="Third slide" />
        <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img7} alt="Third slide" />
        <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img8} alt="Third slide" />
         <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img9} alt="Third slide" />
         <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img10} alt="Third slide" />
         <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img11} alt="Third slide" />
         <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img className="d-block w-100" src={img12} alt="Third slide" />
        <Carousel.Caption>
        <h3>Coming soon...</h3>
          <p>The Secret Sauce</p>
          <p>Crispy Thai Chilli snack</p>
          <p>Tom Yum flavour</p>
          <p>Naam Toak flavour</p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
};

export default Testimonials;
