import React from "react";
import Carousel from "react-bootstrap/Carousel";
import Image from "react-bootstrap/Image";
import VideoPlayer from "react-background-video-player";
import bg from "../assets/bg.jpeg";

import pic1 from "../assets/img/pic1.jpeg";
import pic2 from "../assets/img/pic2.jpeg";
import pic3 from "../assets/img/pic3.jpeg";

import vdBG from "../assets/vdBG.mp4";


const CarouselBanner = () => {
  return (
    <>
      {/* <Carousel fade>
        <Carousel.Item>
          <img className="d-block w-100" src={pic1} alt="First slide" />
          <Carousel.Caption>
            <p>Add to fried rice for spice and crunch.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={pic2} alt="Second slide" />

          <Carousel.Caption>
            <p>
              Sprinkle on poached, scrambled, or fried eggs for a flavourful
              brunch .
            </p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img className="d-block w-100" src={pic3} alt="Third slide" />

          <Carousel.Caption>
            <p>Serve alongside a cold beer as the perfect bar snack.</p>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel> */}
      <VideoPlayer
        className="video"
  
        src={vdBG}
        autoPlay={true}
        loop={true}
        muted={true}
      />

      {/* <div>
<video  className="video" autoPlay loop muted  id='video'>
        <source src={vdBG} type='video/mp4' />  
      </video>

</div> */}
    </>
  );
};

export default CarouselBanner;
