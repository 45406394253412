import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import "./Product.css";



import pic1 from "../../assets/img/pic1.jpeg";
import { Image } from "react-bootstrap";

import AOS from 'aos';

const Product = () => {

  useEffect(() => {
    AOS.init();
  }, [])
  

  return (
    <div className="body-product bg-light">
      <Container fluid>
        <hr />
        <h1 className="text-center">Our Current Product.</h1>
        <Row style={{justifyContent:  'space-around'}}>
          <Col data-aos="fade-up"
     data-aos-anchor-placement="top-center" xs={12} sm={12} md={8} lg={6} >
            <h1> Crispy Thai Chilli Snack </h1>
            <h3 className="">
              {" "}
              is a delicious and versatile product that is packed with flavour
              and made from natural ingredients.
            </h3>
            <p className="">
              Crispy Thai Chilli Snack is vegan and the perfect accompaniment to
              rice, salads, stir-fries, noodles…and delicious as a snack on its
              own! The possibilities are endless…
            </p>
            <ul>
              <li> Add to fried rice for spice and crunch </li>
              <li> Sprinkle on poached, scrambled, or fried eggs for a flavourful brunch </li>
              <li> Add to stir-fries and noodles for another layer of flavour</li>
              <li> Serve alongside a cold beer as the perfect bar snack</li>
            </ul>
          </Col>
          <Col data-aos="fade-up"
     data-aos-anchor-placement="top-center" xs={12} sm={12} md={4} lg={6}>
            <Image src={pic1} width={400} fluid style={{ borderRadius: "25%" }} />
            <p className="mt-5">
              {" "}
              Packed with flavour and made from natural ingredients.
            </p>
          </Col>
        </Row>
        <hr />
      </Container>
    </div>
  );
};

export default Product;
