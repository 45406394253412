import React from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";
import Figure from "react-bootstrap/Figure";

 import { Link} from "react-scroll";


import logo from "../../assets/logo.jpeg";
import Image from "react-bootstrap/esm/Image";



const Header = () => {
  return (
    <>
      {/* <Navbar bg="light" variant="light">
        <Container>
          <Navbar.Brand href="#home">
          
            <Image  src={logo} width={50} roundedCircle />
          </Navbar.Brand>
          <Nav className="m-auto">
            <Link activeClass='active' ration={500} smooth to='home'>
              {" "}
              <Nav.Link >Home</Nav.Link>{" "}
            </Link>
            <Link activeClass='active' ration={500} smooth to='about' >
              {" "}
              <Nav.Link >About</Nav.Link>{" "}
            </Link>
            <Link activeClass='active' ration={500} smooth to='presence'>
              {" "}
              <Nav.Link >Presence</Nav.Link>{" "}
            </Link>
            <Link  activeClass='active' ration={500} smooth to='product'>
              {" "}
              <Nav.Link >Product</Nav.Link>{" "}
            </Link>
            <Link activeClass='active' ration={500} smooth to='testimonial'>
              {" "}
              <Nav.Link >Testimonail</Nav.Link>{" "}
            </Link>
            <Link activeClass='active' ration={500} smooth to='contact'>
              {" "}
              <Nav.Link >Contact</Nav.Link>{" "}
            </Link>
          </Nav>
        </Container>
      </Navbar> */}
         <Navbar collapseOnSelect expand="lg" bg="light" variant="light" sticky="top">
      <Container>
   <Link activeClass='active' ration={500} smooth to='home' >
   <Navbar.Brand >
          
          <Image style={{cursor: 'pointer'}}  src={logo} width={50} roundedCircle />
        </Navbar.Brand>
   </Link>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="m-auto">
            <Link activeClass='active' ration={100} smooth to='home'>
              {" "}
              <Nav.Link >Home</Nav.Link>{" "}
            </Link>
            <Link activeClass='active' ration={100} smooth to='about' >
              {" "}
              <Nav.Link >About</Nav.Link>{" "}
            </Link>
            {/* <Link activeClass='active' ration={100} smooth to='presence'>
              {" "}
              <Nav.Link >Presence</Nav.Link>{" "}
            </Link> */}
            <Link  activeClass='active' ration={100} smooth to='product'>
              {" "}
              <Nav.Link >Product</Nav.Link>{" "}
            </Link>
            {/* <Link activeClass='active' ration={100} smooth to='testimonial'>
              {" "}
              <Nav.Link >Testimonail</Nav.Link>{" "}
            </Link> */}
            <Link activeClass='active' ration={100} smooth to='contact'>
              {" "}
              <Nav.Link >Contact</Nav.Link>{" "}
            </Link>
          </Nav>
          
        </Navbar.Collapse>
      </Container>
    </Navbar>
    </>
  );
};

export default Header;
