import "./App.css";
import CarouselBanner from "./components/CarouselBanner";
import About from "./components/About/About";
import Product from "./components/Product/Product";
import Header from "./components/Header/Header";
import Testimonials from "./components/Testimonial/Testimonials";
import Contact from "./components/Contact/Contact";
import Presence from "./components/Presen/Presence";
import Footer from "./components/Footer/Footer";
import { Helmet } from "react-helmet";

function App() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dee Thai Foods Crispy Thai Chilli Snack</title>
        <meta
          name="description"
          content="Crispy Thai Chilli Snack is vegan and the perfect accompaniment to rice, salads, stir-fries, noodles…and delicious as a snack on its own! The possibilities are endless…"
        ></meta>
        <link rel="canonical" href="https://www.deethaifoods.com.au" />

        <meta
          itemprop="name"
          content="Dee Thai Foods Crispy Thai Chilli Snack"
        />
        <meta
          itemprop="description"
          content="Crispy Thai Chilli Snack is vegan and the perfect accompaniment to rice, salads, stir-fries, noodles…and delicious as a snack on its own! The possibilities are endless…"
        />
        <meta itemprop="image" content="" />
      </Helmet>
      <Header />
      <div name="home" id="home">
        <CarouselBanner />
      </div>
      <div name="presence" id="presence">
        <Presence />
      </div>
      <div name="about" id="about">
        <About />
      </div>
      <div name="product" id="product">
        <Product />
      </div>
      <div name="testimonial" id="testimonial">
        {" "}
        <Testimonials />
      </div>

      <div name="contact">
        {" "}
        {/* <Contact name="contact" id="contact" /> */}
        <Footer name="contact" id="contact" />
      </div>
    </>
  );
}

export default App;
