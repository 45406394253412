import React from "react";
import { Col, Image, Row } from "react-bootstrap";
import "./footer.css";
import { SocialIcon } from "react-social-icons";

import logo from "../../assets/logo.jpeg";

const Footer = () => {
  return (
    <div className="body-footer">
      <div className="container-inside ">
        <Row>
          <Col className="item1" xs={12} sm={12} md={4} lg={4}>
            <Image src={logo} width={100} />
           
            <h2 className="mt-3"> Dee Thai Foods </h2>
          </Col>

          {/* <Col className="item2" xs={12} sm={12} md={4}>
            <div>
              <h1> Wholesale Inquiries </h1>
              <p>17 Templeton St Woodend</p>
              <p> 3442 Victoria Australia </p>
            </div>
          </Col> */}
        
          <Col className="item3" xs={12} sm={12} md={4} lg={4}>
            <h2>Contact</h2>
            <p>
              {" "}
              Tel: <span> +61478941656</span>{" "}
            </p>
            <p>
              Email: <span>  hello@deethaifoods.com.au</span>
            </p>

          
          </Col>
          <Col className="item2" xs={12} sm={12} md={4} lg={4}>
          <h2>
              {" "}
              Follow us: deethaifoods </h2>
             
              <br />
              <span className="social-icon" >
                {" "}
                <SocialIcon
                  url="https://facebook.com/Deethaifoods"
                  className="light-hover mr-2"
                  style={{ borderRadius: "50% " }}
                  fgColor="#ffff"
                />{" "}
              </span>
              <span className="social-icon" >
                {" "}
                <SocialIcon
                  url="https://instagram.com/Deethaifoods"
                  className="light-hover mr-2"
                  style={{ borderRadius: "50% " }}
                  fgColor="#fff"
                />{" "}
              </span>{" "}
              <span  className="social-icon">
                {" "}
                <SocialIcon
                  url="https://www.tiktok.com/@deethaifoods"
                  className="light-hover"
                  style={{ borderRadius: "50% " }}
                  fgColor="#fff"
                />{" "}
              </span>{" "}
           
          </Col>
        </Row>
        <div className="text-center mt-5">
          {" "}
          <h5> Copyright @ 2022 Dee Thai Foods </h5>
          <h5> All rights reserved​ </h5>
        </div>
      </div>
    </div>
  );
};

export default Footer;
